






































































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            rolesArray: [
                "ReportCreator",
                "ReportReviewer",
                "ReportSignatory",
                "Admin",
            ],
            fields: [
                {
                    key: "details",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "roles",
                    label: "Role(s)",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },

                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [
                {
                    fullName: "Benjamin Samson",
                    email: "benjamin.sam@bodadmin.com",
                    imageUrl: "",
                    role: "Creator",
                    entry_status: null,
                },
                // {
                //     name: "Vivien Grace",
                //     email: "vivien.grace@bodadmin.com",
                //     imageUrl: "",
                //     roles: [
                //         {
                //             name: "Director",
                //         },
                //         {
                //             name: "Executive",
                //         },
                //     ],
                //     committees: 3,
                //     entry_status: "Draft",
                // },
            ],
        };
    },
    computed: {
        ...mapState("users", {
            reviewers: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportReviewer",
                )?.users,
        }),
        ...mapGetters({
            levels: "users/levels",
        }),
        reportReviewer(): any {
            return this.levels
                .filter((item: any) => item.category === "ReportReviewer")
                .pop();
        },
        defaultActions() {
            return [
                {
                    text: "Edit",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Remove Reviewer",
                    action: (item: any) => {
                        this.removeReviewer(item.id);
                    },
                },
            ];
        },
        makeActions() {
            return [
                {
                    text: "Make Creator",
                    id: "ReportCreator",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportCreator"],
                            userType: "Creator",
                        });
                    },
                },
                {
                    text: "Make Reviewer",
                    id: "ReportReviewer",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportReviewer"],
                            userType: "Reviewer",
                        });
                    },
                },
                {
                    text: "Make Signatory",
                    id: "ReportSignatory",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportSignatory"],
                            userType: "Signatory",
                        });
                    },
                },
                {
                    text: "Make Admin",
                    id: "Admin",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "Admin"],
                            userType: "Admin",
                        });
                    },
                },
            ];
        },
        itemOptions() {
            return [
                {
                    text: "Edit",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Remove",
                    action: (item: any) => {
                        this.removeReviewer(item.id);
                    },
                },
            ];
        },
    },
    methods: {
        ...mapActions("users", ["fetchLevels"]),
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchPositions: "company/fetchPositions",
            makeCreator: "reports/makeCreator",
            makeReviewer: "reports/makeReviewer",
            updateUserRoles: "users/updateSingleUserRoles",
        }),
        getActions(roleIds: Array<string>): any {
            if (roleIds) {
                const userMakeActions = this.makeActions.filter(
                    (makeAction) => !roleIds.includes(makeAction.id),
                );
                return [...this.defaultActions, ...userMakeActions];
            }
            return [...this.defaultActions, ...this.makeActions];
        },
        async updateRole({
            item,
            roles,
            userType,
        }: {
            item: any;
            roles: Array<string>;
            userType: string;
        }) {
            const { id } = item;

            const body = [...roles];
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Made ${userType} successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchReviewerList();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchReviewerList() {
            this.isLoading = true;
            try {
                await this.fetchLevels();
                console.log(this.reviewers);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async removeReviewer(id: string) {
            const currentIds = this.reviewers.map(
                (reviewer: any) => reviewer.id,
            );
            try {
                this.isLoading = true;
                await this.makeReviewer([
                    ...currentIds.filter(
                        (currentId: string) => currentId !== id,
                    ),
                ]);
            } finally {
                //
                this.isLoading = false;
            }
        },
    },
    mounted() {
        // load the call only if the options are empty
        if (this.reviewers?.length === 0) {
            this.fetchReviewerList();
        }
    },
});
