
















































import Vue from "vue";
import CompanyLogos from "@/app/components/sections/company/CompanyLogos.vue";
import CompanyDetails from "@/app/components/sections/company/CompanyDetails.vue";
// import PlanDetails from "@/app/components/sections/company/PlanDetails.vue";
import { mapActions } from "vuex";

export default Vue.extend({
    components: {
        CompanyDetails,
        CompanyLogos,
        // PlanDetails
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        ...mapActions({
            fetchCompanyProfile: "company/fetchProfile",
        }),
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.fetchCompanyProfile();
        } catch (e) {
            this.$bvToast.toast("", {
                title: "Unable to fetch profile!",
                variant: "error",
                solid: true,
            });
        }
        this.isLoading = false;
    },
});
