
























































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { SETTINGS } from "@/app/routes/endpoints";

export default Vue.extend({
    name: "EditProfile",
    data() {
        return {
            loading: false,
            editProfileInfo: false,
            dropDowns: {
                gender: ["Male", "Female", "Not Specified"],
            },
            formFields: [
                {
                    key: "firstName",
                    label: "First Name",
                    type: "text",
                    disabled: false,
                },
                {
                    key: "lastName",
                    label: "Last Name",
                    type: "text",
                    disabled: false,
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "dropdown",
                    disabled: false,
                },
                {
                    key: "phoneNumber",
                    label: "Phone",
                    type: "phone",
                    disabled: false,
                },
                {
                    key: "email",
                    label: "Email",
                    type: "email",
                    disabled: true,
                },
                {
                    key: "position",
                    label: "Position",
                    type: "text",
                    disabled: false,
                },
                {
                    key: "bio",
                    label: "Bio",
                    type: "textarea",
                    disabled: false,
                },
            ],
            profile: {
                profileImgId: "",
                firstName: "Sample",
                lastName: "Tester",
                phoneNumber: "09019836127",
                email: "test@gmail.com",
                gender: "Male",
                bio: "",
                position: "",
            },
        };
    },
    methods: {
        ...mapActions({
            updateProfile: "auth/updateProfile",
            fetchProfile: "auth/fetchProfile",
        }),
        async goToNextStep() {
            const currentStep = Number(this.$route.query["onboarding-step"]);
            if (currentStep) {
                await this.$router.push({
                    name: SETTINGS.BUSINESS.NAME,
                    query: {
                        "onboarding-step": currentStep + 1,
                    },
                });
            } else {
                try {
                    await this.fetchProfile();
                } catch (e) {
                    this.$bvToast.toast("Error", {
                        title: "Unable to fetch profile! Try again",
                        variant: "error",
                        solid: true,
                    });
                }
            }
        },
        toggleProfileInfo() {
            this.editProfileInfo = !this.editProfileInfo;
        },
        async saveProfile() {
            this.loading = true;
            const payload = { ...this.profile, email: this.profileData.email };
            try {
                const res = await this.updateProfile(payload);
                this.$bvToast.toast(res.data.message, {
                    title: "Profile Update Successful!",
                    variant: "success",
                    solid: true,
                });
                this.editProfileInfo = false;
                this.goToNextStep();
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to update profile. Try again",
                    variant: "error",
                    solid: true,
                });
            }
            this.loading = false;
        },
        setUserInfo() {
            this.profile = { ...this.profile, ...this.profileData };
        },
    },
    validations(): any {
        return {
            profile: {
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
                phoneNumber: {
                    required,
                },
                email: {
                    required,
                    email,
                },
            },
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
    },
    mounted() {
        this.setUserInfo();
    },
});
