





























import Vue from "vue";
import { mapGetters } from "vuex";
import CuMenu from "../../components/settings/CuMenu.vue";
import CuHomeBanner from "../../components/settings/CuHomeBanner.vue";
import { SETTINGS, HOME } from "../../routes/endpoints";

export default Vue.extend({
    components: { CuMenu, CuHomeBanner },
    data() {
        return {
            recentActivities: [],
            HOME,
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        isAdmin(): boolean {
            return this.profileData?.roles?.includes("Admin");
        },
        settingsDivisions(): any {
            const res = [
                {
                    title: "Profile",
                    description: "Edit profile and personal settings",
                    logo: "ba-person",
                    route: SETTINGS.PROFILE.NAME,
                },
            ];
            // if (this.isAdmin) {
            //     res.push(
            //         {
            //             title: "Company Settings",
            //             description:
            //                 "Edit company information and licensing plans",
            //             logo: "ba-company",
            //             route: SETTINGS.BUSINESS.NAME,
            //         },
            //         {
            //             title: "Users, Roles & Permission",
            //             description: "Add/remove users, roles and permissions",
            //             logo: "ba-account-group",
            //             route: SETTINGS.USER_ACCESS.NAME,
            //         },
            //     );
            // }

            return res;
        },
    },
});
