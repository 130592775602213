





































































































































import Vue from "vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

const defaultData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    position: "",
    otherPosition: "",
    roles: [],
    dateOfAppointment: "",
    gender: 0,
};

export default Vue.extend({
    name: "CreateUserModal",
    props: {
        edit: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        userType: {
            type: String,
            default: "User",
        },
        defaultPosition: {
            type: String,
            default: "",
        },
        modalId: {
            type: String,
            required: true,
        },
        preferredPositions: {
            type: Array,
            default: () => [],
        },
        hasPreferredPositions: {
            type: Boolean,
            default: false,
        },
        showRoles: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        edit(val) {
            if (val) {
                this.setForm(this.user);
            } else {
                this.form = { ...defaultData, position: this.defaultPosition };
            }
        },
        user(val) {
            this.setForm(val);
        },
    },
    computed: {
        ...mapGetters({
            positions: "company/companyPositionsText",
        }),
        updateText(): string {
            return this.edit ? "Edit" : "Create";
        },
        updateDescription(): string {
            return this.edit ? "Edit a" : "Add a new";
        },
        positionOptions() {
            return this.hasPreferredPositions
                ? [...this.preferredPositions, "Others"]
                : [...this.positions, "Others"];
        },
    },
    data() {
        return {
            loading: false,
            form: {
                ...defaultData,
                position: this.defaultPosition,
            },
            genders: [
                { id: 0, text: "Not Specified" },
                { id: 1, text: "Male" },
                { id: 2, text: "Female" },
            ],
            rolesArray: [
                { id: "ReportCreator", text: "Report Creator" },
                { id: "ReportReviewer", text: "Report Reviewer" },
                { id: "ReportSignatory", text: "Report Signatory" },
                { id: "Admin", text: "Admin" },
            ],
        };
    },
    methods: {
        ...mapActions({
            saveUser: "users/createUser",
            updateUser: "users/updateSingleUser",
            updateUserRoles: "users/updateSingleUserRoles",
        }),
        inPositions(position: string) {
            return !this.hasPreferredPositions
                ? this.positions.includes(position)
                : this.preferredPositions.includes(position);
        },
        setForm(payload: any) {
            const [firstName, lastName] = payload.fullName.split(" ");
            this.form = {
                firstName,
                lastName,
                email: payload.email,
                phoneNumber: payload.phoneNumber,
                position: this.inPositions(payload.position)
                    ? payload.position
                    : "Others",
                otherPosition: payload.position,
                // roles: [],
                roles: (this.rolesArray.filter((role) =>
                    payload?.roleIds?.includes(role.id),
                ) || []) as any,
                gender: payload.gender,
                dateOfAppointment: payload.dateCreated,
            };
        },

        async createUser() {
            this.loading = true;
            const { position, otherPosition, roles, ...payload } = this.form;
            const roleBody = roles.map((item: any) => item.id);
            const body = {
                ...payload,
                roleIds: roleBody,
                position: position === "Others" ? otherPosition : position,
            };
            try {
                if (this.edit) {
                    await this.updateUser({
                        userId: this.user.id,
                        body,
                    });
                    await this.updateUserRoles({
                        userId: this.user.id,
                        body: roleBody,
                    });
                } else {
                    await this.saveUser({ ...body });
                }
                this.$bvToast.toast("Success", {
                    title: `User ${this.updateText}d Successful!`,
                    variant: "success",
                    solid: true,
                });
                this.$emit("completed");
                this.form = { ...defaultData };
                this.$v.$reset();
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: `Unable to ${this.updateText} User!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.loading = false;
            }
        },
    },
    validations(): any {
        return {
            form: {
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
                email: {
                    required,
                },
                phoneNumber: {
                    required,
                },
                position: {
                    required,
                },
                otherPosition: {
                    required: requiredIf(
                        ({ position }) => position === "Others",
                    ),
                },
            },
        };
    },
});
