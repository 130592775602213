




















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    name: "ProfileImage",
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
            files: "file/getFiles",
        }),
        getInitials(): string {
            const { firstName, lastName } = this.profileData;
            return `${firstName && firstName[0]}${lastName && lastName[0]}`;
        },
        profileImageId(): string {
            const { profileImgId } = this.profileData;
            return profileImgId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileImageId];
            return imageObj && imageObj.presignedUrl;
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            updateProfile: "auth/updateProfile",
            fetchProfile: "auth/fetchProfile",
            uploadFile: "file/uploadFile",
            fetchFile: "file/fetchFile",
            removeFile: "file/removeFile",
        }),
        async loadProfile() {
            try {
                await this.fetchProfile();
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch profile!",
                    variant: "error",
                    solid: true,
                });
            }
        },
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "error",
                    solid: true,
                });
            }
        },
        async processFileUpload(file: any): Promise<void> {
            const { fileName } = file;
            await this.saveProfile({
                ...this.profileData,
                profileImgId: fileName,
            }).then(async () => {
                await this.loadProfile();
                await this.getFileUrl(fileName);
            });
        },
        async removeProfileImage(): Promise<void> {
            this.loading = true;
            try {
                await this.removeFile(this.profileImageId).then(async () => {
                    await this.saveProfile({
                        ...this.profileData,
                        profileImgId: "",
                    });
                    this.$bvToast.toast("", {
                        title: "Profile Image removed!",
                        variant: "success",
                        solid: true,
                    });
                });
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to remove image!",
                    variant: "error",
                    solid: true,
                });
            }
            this.loading = false;
        },
        async saveProfile(payload: any): Promise<void> {
            this.loading = true;
            try {
                await this.updateProfile(payload).then(async () => {
                    await this.loadProfile();
                });
                this.$bvToast.toast("", {
                    title: "Profile Update Successful!",
                    variant: "success",
                    solid: true,
                });
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to update profile!",
                    variant: "error",
                    solid: true,
                });
            }
            this.loading = false;
        },
    },
});
