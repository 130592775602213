










































































import Vue from "vue";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import { mapActions } from "vuex";
import UsersTab from "../../components/tabs/user-access/UsersTab.vue";
import CreatorsTab from "../../components/tabs/user-access/CreatorsTab.vue";
import ReviewersTab from "../../components/tabs/user-access/ReviewersTab.vue";
import AdminsTab from "../../components/tabs/user-access/AdminsTab.vue";
import SignatoriesTab from "../../components/tabs/user-access/SignatoriesTab.vue";

export default Vue.extend({
    components: {
        UsersTab,
        CreatorsTab,
        ReviewersTab,
        CreateUserModal,
        AdminsTab,
        SignatoriesTab,
    },
    data() {
        return {
            user: null,
            isEdit: false,
            isLoading: false,
            tabs: [
                {
                    text: "All Users",
                    value: "users",
                    component: "users-tab",
                },
                {
                    text: "Admins",
                    value: "admins",
                    component: "admins-tab",
                },
                {
                    text: "Creators",
                    value: "creators",
                    component: "creators-tab",
                },
                {
                    text: "Signatories",
                    value: "signatories",
                    component: "signatories-tab",
                },
                {
                    text: "Reviewers",
                    value: "reviewers",
                    component: "reviewers-tab",
                },
            ],
        };
    },
    mounted() {
        this.fetchActiveUsers();
    },
    computed: {
        currentTab(): { text: string; value: string; component: string } {
            const { tab }: { tab?: string } = this.$route.query;

            const res = this.tabs.find((tabItem) => tabItem.value === tab);

            return res ?? this.tabs[0];
        },
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchActiveUsers: "users/fetchActiveUsers",
        }),
        isActiveTab(tab: string): boolean {
            return this.currentTab.value === tab;
        },
        toggleUpdate(user: any) {
            this.user = user;
            this.isEdit = true;
            this.$bvModal.show("modal-create-user");
        },
        toggleCreate() {
            this.user = null;
            this.isEdit = false;
            this.$bvModal.show("modal-create-user");
        },
        async fetchUserList() {
            this.isLoading = true;
            try {
                await this.fetchUsers();
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async loadAfterCompletion() {
            await this.fetchUserList();
            await this.fetchActiveUsers();
            this.$bvModal.hide("modal-create-user");
        },
    },
});
