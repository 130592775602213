
















































import Vue from "vue";
import EditProfile from "@/app/components/sections/profile/EditProfile.vue";
import ChangePassword from "@/app/components/sections/profile/ChangePassword.vue";
import ProfileImage from "@/app/components/sections/profile/ProfileImage.vue";
import {mapActions} from "vuex";

export default Vue.extend({
  components: {
    EditProfile,
    ChangePassword,
    ProfileImage,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchProfile: "auth/fetchProfile",
    }),
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchProfile()
    } catch (e) {
      this.$bvToast.toast('', {
        title: "Unable to fetch profile!",
        variant: "error",
        solid: true,
      });
    }
    this.isLoading = false
  }
});
