























































































import Vue from "vue";
import { formatDateToLocale } from "@/utils/helpers";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { SETTINGS } from "../../../routes/endpoints";

const businessSector = [
    "Financial services",
    "Logistics",
    "Healthcare",
    "Human resources",
    "Information & communication Technology",
    "Manufacturing",
    "Marketing",
    "Media and entertainment",
    "Mining",
    "Oil and gas",
    "Private sector",
    "Professional services",
    "Real estate",
    "Telecommunication",
    "Trade",
    "Tourism",
    "Transportation",
    "Waste management",
    "Venture capital",
    "Others",
];

const companyStructure = [
    "Private Company Limited by Shares",
    "Public Company Limited by Shares",
    "Company Limited by Guarantee",
    "Unlimited Company",
    "Limited Liability Partnership",
    "Limited Partnership",
    "Business Name",
    "Incorporated Trustee",
    "Ministry, Department, or Agency of Federal Government of Nigeria",
    "Others",
];

export default Vue.extend({
    name: "EditProfile",
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
        }),
    },
    watch: {
        companyData(val) {
            if (val.companyProfile) {
                this.profile = { ...val.companyProfile };
            }
        },
    },
    data() {
        return {
            loading: false,
            editDetails: false,
            dropDowns: {
                businessSector,
                companyStructure,
            },
            formFields: [
                {
                    key: "name",
                    label: "Company Name",
                    type: "text",
                    isRequired: true,
                },
                // {
                //   key: "companyEmail",
                //   label: "Company Email",
                //   type: "text",
                // },
                {
                    key: "rcNumber",
                    label: "Incorporation/Registration Number",
                    type: "text",
                    disabled: true,
                    isRequired: true,
                },
                {
                    key: "incorporationDate",
                    label: "Incorporation Date",
                    type: "date",
                    isRequired: true,
                },
                {
                    key: "website",
                    label: "Website",
                    type: "text",
                    isRequired: true,
                },
                {
                    key: "primaryAddress",
                    label: "Primary Address",
                    type: "textarea",
                    isRequired: true,
                },
                {
                    key: "serviceDescription",
                    label: "Business Description",
                    type: "textarea",
                    isRequired: true,
                },
                {
                    key: "companyStructure",
                    label: "Organisation Structure",
                    type: "dropdown",
                    isRequired: true,
                },
                {
                    key: "businessSector",
                    label: "Business Sector",
                    type: "dropdown",
                    isRequired: false,
                },
            ],
            oldData: {
                logoImgUrl: null,
                motto: null,
                brandColor: null,
                licenseNumber: null,
            },
            profile: {
                name: "",
                website: "",
                businessSector: "",
                rcNumber: "",
                incorporationDate: "",
                primaryAddress: "",
                serviceDescription: "",
                companyStructure: "",
            },
        };
    },
    validations(): any {
        return {
            profile: {
                name: {
                    required,
                },
                businessSector: {
                    required,
                },
                website: {},
                primaryAddress: {
                    required,
                },
                rcNumber: {
                    required,
                },
                serviceDescription: {
                    required,
                },
                incorporationDate: {
                    required,
                },
                organisationStructure: {
                    required,
                },
            },
        };
    },
    methods: {
        ...mapActions({
            updateCompanyProfile: "company/updateProfile",
            fetchCompanyProfile: "company/fetchProfile",
            fetchProfile: "auth/fetchProfile",
        }),
        formatDateToLocale,
        toggleEditDetails() {
            this.editDetails = !this.editDetails;
        },
        async goToNextStep() {
            this.loading = true;
            const currentStep = Number(this.$route.query["onboarding-step"]);
            if (currentStep) {
                await this.$router.push({
                    name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": currentStep + 1,
                    },
                });
            } else {
                try {
                    await this.fetchCompanyProfile();
                    await this.fetchProfile();
                } catch (e) {
                    this.$bvToast.toast("Error", {
                        title: "Unable to fetch profile! Try again",
                        variant: "error",
                        solid: true,
                    });
                    this.loading = false;
                } finally {
                    this.loading = false;
                }
            }
        },
        async saveDetails() {
            this.loading = true;
            const payload = { ...this.profile };
            try {
                await this.updateCompanyProfile(payload).then(async (res) => {
                    this.$bvToast.toast(res.data.message, {
                        title: "Profile Update Successful!",
                        variant: "success",
                        solid: true,
                    });
                    try {
                        await this.goToNextStep();
                    } catch (e) {
                        this.$bvToast.toast("Error", {
                            title: "Unable to fetch business profile! Try again",
                            variant: "error",
                            solid: true,
                        });
                    }
                    this.editDetails = false;
                });
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to update business profile!",
                    variant: "error",
                    solid: true,
                });
            }
            this.loading = false;
        },
        setCompanyProfile() {
            this.profile = {
                ...this.profile,
                ...this.companyData.companyProfile,
            };
        },
    },
    mounted() {
        this.setCompanyProfile();
    },
});
