var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"profile-password pt-4 px-4 position-relative"},[_c('div',{staticClass:"profile-password-action"},[(!_vm.editPassword)?_c('ba-button',{staticClass:"text-primary text-decoration-none font-weight-bold",attrs:{"text":"Change Password","variant":"link"},on:{"click":_vm.togglePassword}}):_vm._e()],1),_c('b-col',{attrs:{"lg":_vm.editPassword ? 6 : 12,"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(!_vm.editPassword)?_c('div',{staticClass:"mb-4"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Password")])]),_c('div',{staticClass:"text-black text-sm-3"},[_c('p',[_vm._v("******")])]),_c('div',[(
                                !_vm.editPassword &&
                                _vm.profileData.dateOfPasswordUpdate
                            )?_c('p',{staticClass:"text-sm-3 text-right"},[_vm._v(" Last changed: "+_vm._s(_vm.formatDate(_vm.profileData.dateOfPasswordUpdate))+" ")]):_vm._e()])],1):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ba-text-field',{attrs:{"label":"Enter old password","placeholder":"********","required":"","type":_vm.isOldPasswordVisible ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.passwordFields.oldPassword.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-icon',{staticClass:"mx-2 pointer",attrs:{"font-scale":"1.3","icon":!_vm.isOldPasswordVisible
                                            ? 'eye-fill'
                                            : 'eye-slash-fill'},on:{"click":function($event){_vm.isOldPasswordVisible =
                                            !_vm.isOldPasswordVisible}}})]},proxy:true}]),model:{value:(_vm.passwordFields.oldPassword),callback:function ($$v) {_vm.$set(_vm.passwordFields, "oldPassword", $$v)},expression:"passwordFields.oldPassword"}}),(_vm.$v.passwordFields.oldPassword.$error)?_c('div',{staticClass:"error"},[_vm._v("Former Password is required")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ba-text-field',{attrs:{"label":"Enter new password","placeholder":"********","required":"","type":_vm.isNewPasswordVisible ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.passwordFields.newPassword.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-icon',{staticClass:"mx-2 pointer",attrs:{"font-scale":"1.3","icon":!_vm.isNewPasswordVisible
                                            ? 'eye-fill'
                                            : 'eye-slash-fill'},on:{"click":function($event){_vm.isNewPasswordVisible =
                                            !_vm.isNewPasswordVisible}}})]},proxy:true}]),model:{value:(_vm.passwordFields.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordFields, "newPassword", $$v)},expression:"passwordFields.newPassword"}}),(_vm.$v.passwordFields.newPassword.$error)?_c('div',{staticClass:"error"},[_vm._v("Password is required")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ba-text-field',{attrs:{"label":"Confirm password","placeholder":"********","required":"","type":_vm.isNewConfirmPasswordVisible
                                    ? 'text'
                                    : 'password'},on:{"blur":function($event){return _vm.$v.passwordFields.confirmPassword.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-icon',{staticClass:"mx-2 pointer",attrs:{"font-scale":"1.3","icon":!_vm.isNewConfirmPasswordVisible
                                            ? 'eye-fill'
                                            : 'eye-slash-fill'},on:{"click":function($event){_vm.isNewConfirmPasswordVisible =
                                            !_vm.isNewConfirmPasswordVisible}}})]},proxy:true}]),model:{value:(_vm.passwordFields.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordFields, "confirmPassword", $$v)},expression:"passwordFields.confirmPassword"}}),(_vm.$v.passwordFields.confirmPassword.$error)?_c('div',{staticClass:"error"},[_vm._v("Password must match")]):_vm._e()],1)],1)],1)],1)],1),(_vm.editPassword)?_c('b-col',{staticClass:"d-flex flex-column justify-content-end align-items-end",attrs:{"lg":_vm.editPassword ? 6 : 12,"cols":"12"}},[_c('ba-button',{staticClass:"mb-4 text-black",attrs:{"loading":_vm.loading,"text":"Save Changes","variant":"warning"},on:{"click":_vm.savePassword}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }