






























































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "../../../../utils/helpers";

export default Vue.extend({
    name: "ChangePassword",
    data() {
        return {
            loading: false,
            editPassword: false,
            isOldPasswordVisible: false,
            isNewPasswordVisible: false,
            isNewConfirmPasswordVisible: false,
            passwordFields: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            },
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
    },
    validations(): any {
        return {
            passwordFields: {
                oldPassword: {},
                newPassword: {},
                confirmPassword: {},
            },
        };
    },
    methods: {
        formatDate,
        ...mapActions({
            changePassword: "auth/changePassword",
            fetchProfile: "auth/fetchProfile",
        }),
        togglePassword() {
            this.editPassword = !this.editPassword;
        },
        async savePassword() {
            this.loading = true;
            const payload = { ...this.passwordFields };
            try {
                await this.changePassword(payload);

                await this.fetchProfile();
            } catch (e) {
                this.$bvToast.toast("Something went wrong!", {
                    title: "Unable to change password!",
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.editPassword = false;

                this.loading = false;
            }
        },
    },
});
