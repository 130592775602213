
































































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
    components: {},
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            rolesArray: [
                "ReportCreator",
                "ReportReviewer",
                "ReportSignatory",
                "Admin",
            ],
            fields: [
                {
                    key: "details",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "roles",
                    label: "Role(s)",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },

                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [
                {
                    fullName: "Benjamin Samson",
                    email: "benjamin.sam@bodadmin.com",
                    imageUrl: "",
                    role: "Creator",
                    entry_status: null,
                },
                // {
                //     name: "Vivien Grace",
                //     email: "vivien.grace@bodadmin.com",
                //     imageUrl: "",
                //     roles: [
                //         {
                //             name: "Director",
                //         },
                //         {
                //             name: "Executive",
                //         },
                //     ],
                //     committees: 3,
                //     entry_status: "Draft",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters({
            activeUsers: "users/activeUsers",
        }),
        ...mapState("users", ["users"]),
        ...mapState("users", {
            creators: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportCreator",
                )?.users,
        }),
        ...mapState("users", {
            reviewers: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportReviewer",
                )?.users,
        }),
        ...mapState("users", {
            signatories: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportSignatory",
                )?.users,
        }),
        defaultActions() {
            return [
                {
                    text: "Edit",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Disable User",
                    action: (item: any) => {
                        this.deleteUser(item.id);
                    },
                },
                {
                    text: "Send Credentials",
                    action: (item: any) => {
                        this.sendUserCredentials(item.id);
                    },
                },
            ];
        },
        makeActions() {
            return [
                {
                    text: "Make Creator",
                    id: "ReportCreator",
                    action: (item: any) => {
                        this.handleMakeCreator(item.id);
                    },
                },
                {
                    text: "Make Reviewer",
                    id: "ReportReviewer",
                    action: (item: any) => {
                        this.handleMakeReviewer(item.id);
                    },
                },
                {
                    text: "Make Signatory",
                    id: "ReportSignatory",
                    action: (item: any) => {
                        this.handleMakeSignatory(item.id);
                    },
                },
                {
                    text: "Make Admin",
                    id: "Admin",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "Admin"],
                            userType: "Admin",
                        });
                    },
                },
            ];
        },
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchActiveUsers: "users/fetchActiveUsers",
            sendCredentials: "users/sendUserCredentials",
            fetchPositions: "company/fetchPositions",
            makeCreator: "reports/makeCreator",
            makeReviewer: "reports/makeReviewer",
            makeSignatory: "reports/makeSignatory",
            updateUserRoles: "users/updateSingleUserRoles",
            deleteSingleUser: "users/deleteSingleUser",
        }),
        getActions(roleIds: Array<string>): any {
            if (roleIds) {
                const userMakeActions = this.makeActions.filter(
                    (makeAction) => !roleIds.includes(makeAction.id),
                );
                return [...this.defaultActions, ...userMakeActions];
            }
            return [...this.defaultActions, ...this.makeActions];
        },
        async deleteUser(userId: string) {
            this.isLoading = true;
            try {
                await this.deleteSingleUser(userId);

                this.$bvToast.toast("Success", {
                    title: `User removed successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchActiveUsers();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to remove User!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateRole({
            item,
            roles,
            userType,
        }: {
            item: any;
            roles: Array<string>;
            userType: string;
        }) {
            const { id } = item;

            const body = [...roles];
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Made ${userType} successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchActiveUsers();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async handleMakeCreator(id: string) {
            this.isLoading = true;
            try {
                const payload = [id];

                const currentIds = this.creators.map(
                    (creator: any) => creator.id,
                );

                await this.makeCreator([...payload, ...currentIds]);

                this.$bvToast.toast("Success", {
                    title: `Made Creator successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchActiveUsers();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make Creator!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async handleMakeReviewer(id: string) {
            this.isLoading = true;
            try {
                const payload = [id];

                const currentIds = this.reviewers.map(
                    (reviewer: any) => reviewer.id,
                );
                await this.makeReviewer([...payload, ...currentIds]);

                this.$bvToast.toast("Success", {
                    title: `Made Reviewer successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchActiveUsers();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make Reviewer!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async handleMakeSignatory(id: string) {
            this.isLoading = true;
            try {
                const payload = [id];

                const currentIds = this.signatories.map(
                    (signatory: any) => signatory.id,
                );
                await this.makeSignatory([...payload, ...currentIds]);

                this.$bvToast.toast("Success", {
                    title: `Made Signatory successfully`,
                    variant: "error",
                    solid: true,
                });
                await this.fetchActiveUsers();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make Signatory!`,
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },

        async sendUserCredentials(userId: string) {
            //
            try {
                this.isLoading = true;
                const res = await this.sendCredentials(userId);
                console.log(res);
                this.$bvToast.toast(res.message, {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                });
            } catch (error) {
                this.isLoading = false;
                // this.$bvToast.toast("Error", {
                //     title: `Unable to send credentials`,
                //     variant: "error",
                //     solid: true,
                // });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchCompanyPositions() {
            this.isLoading = true;
            try {
                await this.fetchPositions();
            } catch (error) {
                // return error;
            }
            this.isLoading = false;
        },
        async fetchUserList() {
            this.isLoading = true;
            try {
                await this.fetchUsers();
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        if (this.users.length === 0) {
            this.fetchUserList();
        }
        this.fetchCompanyPositions();
    },
});
